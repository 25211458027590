
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import ExplicitHeightElement from '~/components/_general/ExplicitHeightElement.vue'

export default Vue.extend({
  name: 'ShowMore',
  components: { ExplicitHeightElement },
  props: {
    /** Used when lineCount is 0 */
    defaultHeight: {
      type: Number,
      default: 0,
    },
    lineCount: {
      type: Number,
      default: 0,
      validator(n: number) {
        return n >= 0
      },
    },
    openedText: {
      type: String as PropType<string | TranslateResult>,
      default(): TranslateResult {
        return this.$t('Show Less')
      },
    },
    closedText: {
      type: String as PropType<string | TranslateResult>,
      default(): TranslateResult {
        return this.$t('Show More')
      },
    },
  },
  data() {
    return {
      open: false,
      mounted: false,
      showButton: !this.lineCount,
      currentLineCount: this.lineCount,
    }
  },
  computed: {
    clampChild(): HTMLElement {
      return this.$refs.clampChild as HTMLElement
    },
    /** The actual amount of lines we'll clamp to */
    lineClampStyles(): Record<string, string | number> {
      if (this.iosOpen) return {}
      return {
        '-webkit-line-clamp': `${this.currentLineCount} !important`,
      }
    },
    /** The base clamp styles. The actual amount of lines will be modified with lineClampStyles */
    lineClampClasses(): string {
      return this.iosOpen ? '' : 'line-clamp-1'
    },
    lineClampOpenSpeed(): number {
      return 2
    },
    lineClampCloseSpeed(): number {
      return -8
    },
    /** iOS has some weird behaviors with line clamp that makes the styles sub-par
     * so when it's open on iOS, we want to just show the original contents */
    iosOpen(): boolean {
      return this.$device.isIos && this.open
    },
  },
  methods: {
    toggleOpen() {
      this.open = !this.open
      if (!this.lineCount) return
      this.updateLineCount()
    },
    updateLineCount() {
      const count = this.open
        ? this.lineClampOpenSpeed
        : this.lineClampCloseSpeed
      this.currentLineCount = Math.max(
        this.lineCount,
        this.currentLineCount + count
      )
    },
    resize({ height }: Record<'height', number>) {
      const childHeight = Math.max(
        this.clampChild.getBoundingClientRect().height,
        this.clampChild.scrollHeight
      )
      const clamp = childHeight > height
      if (this.open) {
        if (clamp) {
          this.updateLineCount()
        }
      } else {
        this.showButton = clamp
        if (this.currentLineCount > this.lineCount) {
          this.updateLineCount()
        }
      }
    },
  },
})
