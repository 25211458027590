import { render, staticRenderFns } from "./PrimarySpeakers.vue?vue&type=template&id=09377e3e&scoped=true"
import script from "./PrimarySpeakers.vue?vue&type=script&lang=ts"
export * from "./PrimarySpeakers.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09377e3e",
  null
  
)

export default component.exports