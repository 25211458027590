
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { Broadcaster } from '~/models/broadcaster'
import SiteTitledSection from '~/components/site/TitledSection.vue'
import SpeakerElement from '~/components/_general/SpeakerElement.vue'
import { Speaker } from '~/models/speaker'
import { SpeakerDisplay } from '~/assets/ts/enums'

export default Vue.extend({
  name: 'BroadcasterPrimarySpeakers',
  components: { SpeakerElement, SiteTitledSection },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      required: true,
    },
    seeAllTo: {
      type: String,
      default: '',
    },
  },
  computed: {
    display(): SpeakerDisplay {
      return SpeakerDisplay.Expanded
    },
    speakers(): Speaker[] {
      return this.broadcaster.primarySpeakers ?? []
    },
    single(): boolean {
      return this.speakers.length === 1
    },
    title(): TranslateResult {
      if (this.single) return this.$t('Primary Speaker')
      return this.$t('Primary Speakers')
    },
  },
})
