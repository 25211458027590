
import Vue, { PropType } from 'vue'
import {
  DisplayArticleApi,
  ReleaseNotesCategoryID,
} from '~/apiclient/apiarticles'
import SaIcon from '~/components/_general/SaIcon.vue'
import SaImage from '~/components/_general/SaImage.vue'
import { webpUrl } from '~/assets/ts/utils/image'
import { SaIconsType } from '~/assets/ts/types/icons'
import SaLinkOrDjango from '~/components/_general/SaLinkOrDjango.vue'

export default Vue.extend({
  name: 'ArticleThumbnail',
  components: { SaLinkOrDjango, SaImage, SaIcon },
  props: {
    article: {
      type: Object as PropType<DisplayArticleApi>,
      default: undefined,
    },
    width: {
      type: Number,
      default: 1920,
    },
    url: {
      type: String,
      required: true,
    },
    django: {
      type: Boolean,
    },
  },
  computed: {
    imageUrl(): string {
      if (!this.article?.bannerURL) return ''
      return webpUrl(this.article.bannerURL, { width: this.width })
    },
    icon(): SaIconsType {
      if (this.article?.categoryID === ReleaseNotesCategoryID) return 'tools'
      return 'paragraph'
    },
  },
  methods: {},
})
