
import Vue from 'vue'
import ArticleList from '~/components/article/List.vue'
import SiteTitledSection from '~/components/site/TitledSection.vue'

export default Vue.extend({
  name: 'BroadcasterPinnedArticles',
  components: { SiteTitledSection, ArticleList },
  props: {
    broadcasterID: {
      type: String,
      required: true,
    },
    placeholder: {
      type: Boolean,
    },
  },
  computed: {
    hasPinned(): boolean | undefined {
      return this.$store.getters['articles/hasPinnedArticles'](
        this.broadcasterID
      )
    },
    check(): boolean {
      return this.hasPinned !== false
    },
    show(): boolean {
      return this.hasPinned === undefined ? this.placeholder : this.hasPinned
    },
  },
  methods: {
    pinnedCount(totalCount: number) {
      this.$store.commit('articles/ADD_HAS_PINNED_ARTICLES', {
        broadcasterID: this.broadcasterID,
        pinned: totalCount > 0,
      })
    },
  },
})
