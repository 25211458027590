import { Broadcaster } from '~/models/broadcaster'

const soloRootUrl = '/solo/'

function getSoloBaseUrl(broadcaster: Broadcaster) {
  // This assumes we've already checked if the broadcaster's solo site is disabled
  return (
    broadcaster.soloSite?.baseUrl ??
    `https://sermonaudio.com${soloRootUrl}${broadcaster.id}/`
  )
}

export function soloUrl(broadcaster: Broadcaster, page = '') {
  return `${getSoloBaseUrl(broadcaster)}${page}`
}
