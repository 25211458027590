
import Vue, { PropType } from 'vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import BroadcasterPhone from '~/components/broadcaster/Phone.vue'
import BroadcasterContactButton from '~/components/broadcaster/ContactButton.vue'
import BroadcasterDonateButton from '~/components/broadcaster/DonateButton.vue'
import { Broadcaster } from '~/models/broadcaster'

export default Vue.extend({
  name: 'BroadcasterQuickInfo',
  components: {
    BroadcasterDonateButton,
    BroadcasterContactButton,
    BroadcasterPhone,
    InlineIcon,
  },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      required: true,
    },
  },
  computed: {
    website(): Record<'display' | 'url', string> | undefined {
      if (this.broadcaster.homePageURL) {
        return {
          display: this.broadcaster.homePageDisplayUrl,
          url: this.broadcaster.homePageURL,
        }
      } else if (!this.broadcaster.soloSiteDisabled) {
        const url = this.broadcaster.SoloUrl
        return {
          display: this.broadcaster.soloSite ? url : 'Solo Site',
          url,
        }
      }
      return undefined
    },
  },
})
