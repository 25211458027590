
import Vue, { PropType } from 'vue'
import { UserComment } from '~/models/usercomment'
import { Sermon } from '~/models/sermon'
import { DateFormat } from '~/assets/ts/enums'
import { localizeDateTime } from '~/assets/ts/utils/date'
import SiteFeaturedItemLayout from '~/components/site/featured/ItemLayout.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import SermonElement from '~/components/_general/SermonElement.vue'

export default Vue.extend({
  name: 'SiteFeaturedCommentElement',
  components: { SermonElement, LoadingElement, SiteFeaturedItemLayout },
  props: {
    comment: {
      type: Object as PropType<UserComment>,
      default: undefined,
    },
    includeSermon: {
      type: Boolean,
    },
  },
  computed: {
    commentTitle(): string {
      return this.comment ? this.comment.title : ''
    },
    commentText(): string {
      return this.comment?.text ?? ''
    },
    commentAuthor(): string {
      return this.comment ? this.comment.displayName : ''
    },
    commentSermon(): Sermon | undefined {
      return this.comment ? this.comment.sermon : undefined
    },
    commentDate(): string {
      return this.comment
        ? localizeDateTime(this.comment.commentDateTime, DateFormat.ShortDate)
        : ''
    },
  },
})
