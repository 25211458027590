
import Vue from 'vue'
import SiteFilteredSeriesList, {
  SiteSeriesFilteredListProps,
} from '../FilteredSeriesList.vue'
import SiteTitledSection, {
  SiteTitledSectionProps,
} from '~/components/site/TitledSection.vue'

export default Vue.extend({
  name: 'SiteSeriesFilteredListWithTitle',
  components: { SiteFilteredSeriesList, SiteTitledSection },
  props: {
    ...SiteTitledSectionProps,
    ...SiteSeriesFilteredListProps,
    topBorder: {
      type: Boolean,
    },
    addMargin: {
      type: Boolean,
    },
    hideIfEmpty: {
      type: Boolean,
    },
  },
  data() {
    return {
      count: undefined as number | undefined,
    }
  },
  computed: {
    hide(): boolean {
      return this.hideIfEmpty && this.count === 0
    },
  },
})
