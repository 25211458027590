import { Sermon } from '~/models/sermon'
import { SermonRequestOptions } from '~/apiclient/apisermons'
import { returnUndefinedOnError } from '~/assets/ts/utils/misc'
import { ApiClient } from '~/apiclient/apiclient'

/**
 * Loads sermonListToLoad with sermons according to specified options.
 * Excludes sermon with excludeSermonID from resulting list.
 *
 * @param apiClient
 * @param sermonListToLoad
 * @param options
 * @param excludeSermonID
 */
export async function getSermonsHelper(
  apiClient: ApiClient,
  sermonListToLoad: Sermon[],
  options: Partial<SermonRequestOptions>,
  excludeSermonID?: string
) {
  await apiClient
    .getFilteredSermonList(options)
    .then((data) => {
      data.results.forEach((s: Record<string, any>) => {
        if (!excludeSermonID || s.id !== excludeSermonID) {
          sermonListToLoad.push(new Sermon(s))
        }
      })
    })
    .catch(returnUndefinedOnError)
}
