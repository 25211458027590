
import Vue from 'vue'
import { User } from '~/models/users/user'
import { ToastApiError, ToastSuccess } from '~/assets/ts/utils/toast'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SiteButton from '~/components/site/Button.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'

export default Vue.extend({
  name: 'SiteUserVerificationWarning',
  components: { HorizontalRule, SiteButton, InlineIcon },
  props: {
    prevent: {
      type: Boolean,
    },
    modalOnly: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
  },

  computed: {
    verified(): boolean {
      return !this.user || this.user.verified
    },
    email(): string {
      return this.user?.email ?? ''
    },
    user(): User | undefined {
      return this.$users.user
    },
  },
  mounted() {
    if (!this.modalOnly) return
    this.openVerifyModal()
  },
  methods: {
    openVerifyModal() {
      this.$emit('click')
      if (this.prevent) return
      this.$modal.show('verifyModal')
    },
    hideVerifyModal() {
      this.$modal.hide('verifyModal')
    },
    async sendVerifyEmail() {
      if (!this.user) return
      try {
        await this.$users.resendActivationCode(this.user.username)
        ToastSuccess(this.$t('Verification email sent successfully.'))
      } catch (e) {
        ToastApiError(e, this)
      }
    },
  },
})
