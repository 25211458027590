
import Vue from 'vue'
import { sendMessageToIframeParent } from '~/assets/ts/utils/iframe'
import SaLinkOrHref from '~/components/_general/SaLinkOrHref.vue'
import { DjangoUrl } from '~/assets/ts/utils/dashboard'

export default Vue.extend({
  name: 'SaLinkOrDjango',
  components: { SaLinkOrHref },
  props: {
    django: {
      type: Boolean,
    },
    tab: {
      type: Boolean,
    },
    href: {
      type: String,
      required: true,
    },
  },
  computed: {
    isTo(): boolean {
      return !this.django && !this.tab
    },
    url(): string {
      return this.django ? DjangoUrl(this.href) : this.href
    },
  },
  methods: {
    navigate(event: Event) {
      if (!this.django) return
      event.preventDefault()
      sendMessageToIframeParent('navigate', this.href)
    },
  },
})
