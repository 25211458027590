import { render, staticRenderFns } from "./BroadcasterPin.vue?vue&type=template&id=5d31508c&scoped=true"
import script from "./BroadcasterPin.vue?vue&type=script&lang=ts"
export * from "./BroadcasterPin.vue?vue&type=script&lang=ts"
import style0 from "./BroadcasterPin.vue?vue&type=style&index=0&id=5d31508c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d31508c",
  null
  
)

export default component.exports