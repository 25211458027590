
import Vue from 'vue'
import { StadiaMapStyle } from '~/assets/ts/enums'
import MapBroadcasterPin from '~/components/map/BroadcasterPin.vue'
import { MapCoreProps } from '~/models/map'
import MapCore from '~/components/map/Core.vue'
import SaIcon from '~/components/_general/SaIcon.vue'

let LTileLayer, LControl
if (process.client) {
  require('leaflet')
  ;({ LTileLayer, LControl } = require('vue2-leaflet/dist/vue2-leaflet.min'))
}

export default Vue.extend({
  name: 'MapElement',
  components: { SaIcon, MapCore, MapBroadcasterPin, LTileLayer, LControl },
  props: {
    ...MapCoreProps,
    mapStyle: {
      type: Number,
      default: StadiaMapStyle.AlidadeSmoothLight,
    },
    styleOverridesMarkerColor: {
      type: Boolean,
      default: true,
    },
    stylePicker: {
      type: Boolean,
    },
  },
  data() {
    return {
      stadiaStyle: this.mapStyle,
      StadiaMapStyle,
    }
  },
  computed: {
    backgroundColor(): string {
      switch (this.stadiaStyle) {
        case StadiaMapStyle.AlidadeSmoothDark:
          return 'dark-bg'
        case StadiaMapStyle.Outdoors:
          return 'outdoors-bg'
        case StadiaMapStyle.OSMBright:
          return 'bright-bg'
        case StadiaMapStyle.AlidadeSmoothLight:
        default:
          return 'light-bg'
      }
    },
    styleUrlString(): string {
      switch (this.stadiaStyle) {
        case StadiaMapStyle.AlidadeSmoothDark:
          return 'alidade_smooth_dark'
        case StadiaMapStyle.Outdoors:
          return 'outdoors'
        case StadiaMapStyle.OSMBright:
          return 'osm_bright'
        case StadiaMapStyle.AlidadeSmoothLight:
        default:
          return 'alidade_smooth'
      }
    },
    tilesUrl(): string {
      return `https://tiles.stadiamaps.com/tiles/${this.styleUrlString}/{z}/{x}/{y}{r}.png`
    },
    attribution(): string {
      const c = '&copy; '
      const osmLink =
        '<a href="https://www.openstreetmap.org/copyright" target="blank" aria-label="' +
        this.$t('Copyright Open Street Map contributors') +
        '">OSM</a>'
      const stadiaLink =
        '<a href="https://stadiamaps.com/" target="blank" aria-label="' +
        this.$t('Copyright Stadia Maps') +
        '">Stadia Maps</a>'

      const osm = `${c}${this.$t('{osm} contributors', { osm: osmLink })}`
      const stadia = `${c}${stadiaLink}`

      return `${osm}, ${stadia}`
    },
  },
  watch: {
    mapStyle() {
      this.setStyle(this.mapStyle)
    },
  },
  methods: {
    setStyle(style: StadiaMapStyle) {
      this.stadiaStyle = style
    },
  },
})
