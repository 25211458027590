
import Vue, { PropType } from 'vue'
import { apiTimeToDisplayTime, dayOfWeek } from '~/assets/ts/utils/date'
import { Broadcaster } from '~/models/broadcaster'
import MarkdownElement from '~/components/markdown/Element.vue'

export default Vue.extend({
  name: 'BroadcasterServiceTimes',
  components: { MarkdownElement },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      required: true,
    },
  },
  methods: { apiTimeToDisplayTime, dayOfWeek },
})
