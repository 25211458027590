var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('SiteLayoutWithSideContent',{attrs:{"collapsed-gap":true},scopedSlots:_vm._u([{key:"main",fn:function(){return [(_vm.broadcaster.canWebcast)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.webcast),expression:"webcast"}],staticClass:"mb-8"},[_c('VerticalAlign',{staticClass:"text-lg"},[_c('WebcastPing',{staticClass:"inline-block"}),_vm._v(" "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.$t("We're Live!")))])],1),_vm._v(" "),_c('HorizontalRule',{staticClass:"mt-1"}),_vm._v(" "),_c('WebcastElement',{attrs:{"webcast":_vm.webcast,"display":_vm.webcastDisplay}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-y-10 2col:gap-y-16"},[(_vm.recentNotice)?_c('div',{staticClass:"space-y-2"},[_c('BroadcasterNotice',{attrs:{"notice":_vm.recentNotice,"show-label":""}}),_vm._v(" "),_c('BroadcasterRecentNotices',{attrs:{"recent-count":_vm.recentNoticeCount,"broadcaster":_vm.broadcaster}})],1):_vm._e(),_vm._v(" "),_c('BroadcasterQuickInfo',{staticClass:"2col:hidden",attrs:{"broadcaster":_vm.broadcaster}}),_vm._v(" "),(_vm.highlightedSermon || !_vm.mounted)?_c('SiteTitledSection',{attrs:{"title":_vm.$t('Highlighted Sermon')}},[_c('SermonElement',{attrs:{"display":_vm.SermonDisplay.Featured,"hide-description":"","sermon":_vm.highlightedSermon,"hide-broadcaster-image":""}})],1):_vm._e(),_vm._v(" "),_c('BroadcasterPinnedArticles',{attrs:{"broadcaster-i-d":_vm.broadcasterID}}),_vm._v(" "),(
            _vm.broadcaster.numberOfSermons && (!_vm.mounted || _vm.recentSermons.length)
          )?_c('SiteSermonListWithTitle',{attrs:{"title":_vm.$t('Recent Sermons'),"to":_vm.recentSermonsUrl,"display":_vm.SermonDisplay.ExtendedWrap,"sermons":_vm.recentSermons,"max-extended-info":_vm.SermonMetadataExtendedInfo.Series,"placeholder-count":_vm.recentSermonsCount,"loaded":_vm.mounted}}):_vm._e(),_vm._v(" "),(_vm.comment)?_c('SiteTitledSection',{attrs:{"title":_vm.$t('Recent Comments'),"to":_vm.recentComments}},[_c('SiteFeaturedCommentElement',{attrs:{"comment":_vm.comment,"include-sermon":true}})],1):_vm._e(),_vm._v(" "),_c('SiteSeriesFilteredListWithTitle',{attrs:{"title":_vm.$t('Recently Updated Series'),"to":_vm.recentSeriesUrl,"series-options":_vm.recentSeriesOptions,"infinite":false,"broadcaster-i-d":_vm.broadcaster.id,"show-count":false,"display":_vm.SeriesDisplay.Extended,"include-broadcaster":false,"no-results-text":_vm.$t('No Recently Updated Series'),"include-updated-date":"","disable-sort":"","async":"","hide-if-empty":""}}),_vm._v(" "),_c('BroadcasterPrimarySpeakers',{attrs:{"broadcaster":_vm.broadcaster,"see-all-to":_vm.speakersUrl}}),_vm._v(" "),(_vm.broadcaster.aboutUs)?_c('SiteTitledSection',{attrs:{"title":_vm.$t('About Us')}},[_c('ShowMore',{attrs:{"line-count":3}},[_c('MarkdownElement',{staticClass:"!block",attrs:{"body":_vm.broadcaster.aboutUs}})],1)],1):_vm._e()],1)]},proxy:true},{key:"side",fn:function(){return [_c('div',{staticClass:"space-y-10"},[(_vm.broadcaster.welcomeVideoID)?_c('SiteTitledSection',{attrs:{"title":_vm.$t('Welcome Video')}},[_c('SermonElement',{staticClass:"max-w-lg",attrs:{"small":"","hide-description":"","autoplay-preview":"","sermon":_vm.welcomeSermon,"display":_vm.SermonDisplay.VideoWrap}})],1):_vm._e(),_vm._v(" "),_c('BroadcasterQuickInfo',{staticClass:"1col:hidden",attrs:{"broadcaster":_vm.broadcaster}}),_vm._v(" "),(_vm.showInfo)?_c('SiteTitledSection',{attrs:{"title":_vm.$t('Basic Information')}},[_c('SiteDataTable',{attrs:{"small":"","data":[
              {
                label: _vm.$t('Group'),
                value: _vm.broadcaster.denomination,
                to: _vm.broadcasterGroupUrl,
              },
              {
                label: _vm.$t('Attendance'),
                value: _vm.broadcaster.churchSizeRange,
              },
              {
                label: _vm.$t('Bible'),
                value: _vm.broadcaster.bibleVersion,
              },
              {
                label: _vm.$t('Type'),
                value: _vm.broadcasterCategories,
              },
              {
                label: _vm.$t('Sermons'),
                value: _vm.broadcaster.numberOfSermons,
              },
              {
                label: _vm.$t('Joined'),
                value: _vm.broadcasterJoinDate,
              },
              {
                label: 'ID',
                value: _vm.broadcaster.id,
              },
              {
                label: 'Code #',
                value: _vm.broadcaster.idCode,
              },
            ]}})],1):_vm._e(),_vm._v(" "),(_vm.socialAccounts)?_c('SiteTitledSection',{attrs:{"title":_vm.$t('Social Links')}},[_c('div',{staticClass:"space-y-2 flex flex-col"},_vm._l((_vm.socialAccounts),function(link,index){return _c('a',{key:`social-links-${index}`,staticClass:"social-link text-sm",attrs:{"href":link.url,"target":"_blank"}},[_c('InlineIcon',{attrs:{"icon":_vm.GetSocialLinkInfo(link).icon}},[_vm._v(_vm._s(link.label))])],1)}),0)]):_vm._e(),_vm._v(" "),_c('BroadcasterListenLine',{attrs:{"broadcaster":_vm.broadcaster}}),_vm._v(" "),(_vm.broadcaster.hasServiceTimes)?_c('SiteTitledSection',{attrs:{"title":_vm.$t('Service Times')}},[_c('BroadcasterServiceTimes',{attrs:{"broadcaster":_vm.broadcaster}})],1):_vm._e(),_vm._v(" "),(_vm.address || _vm.mailingAddress)?_c('SiteTitledSection',{attrs:{"title":_vm.$t('Address')}},[_c('div',{staticClass:"space-y-6"},[(_vm.address)?_c('div',{staticClass:"space-y-2"},[_c('div',{staticClass:"uppercase text-sm text-gray-metadata"},[_vm._v("\n                "+_vm._s(_vm.$t('Physical Address'))+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"whitespace-pre-wrap text-sm"},[_c('span',[_vm._v(_vm._s(_vm.address))])])]):_vm._e(),_vm._v(" "),(_vm.mailingAddress)?_c('div',{staticClass:"space-y-2"},[_c('div',{staticClass:"uppercase text-sm text-gray-metadata"},[_vm._v("\n                "+_vm._s(_vm.$t('Mailing Address'))+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"whitespace-pre-wrap text-sm"},[_c('span',[_vm._v(_vm._s(_vm.mailingAddress))])])]):_vm._e()])]):_vm._e()],1)]},proxy:true}])}),_vm._v(" "),_c('client-only',[_c('div',{staticClass:"relative w-full h-64"},[_c('a',{attrs:{"href":_vm.googleMapLink,"target":"_blank"}},[_c('MapElement',{attrs:{"lat-lng":_vm.broadcaster.latLng,"map-style":_vm.mapStyle}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }