
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { getStrValuesFromEnum } from '~/assets/ts/enums'
import { FancyDropdownOption } from '~/components/_general/FancyDropdown.vue'
import SortDropdown from '~/components/sort/Dropdown.vue'
import { qsValue } from '~/assets/ts/utils/params'

const sortKey = 'sort'

export enum SeriesSortOptions {
  Updated = 'last_updated',
  Newest = 'newest_sermon_create_date',
  Alphabetical = 'title',
  MostSermons = 'sermon_count_highest',
  LeastSermons = 'sermon_count_lowest',
}

export function SeriesSortQsPair(sort: SeriesSortOptions) {
  return { key: sortKey, value: sort }
}

export function GetSeriesSortFromQs(
  context: Record<any, any>,
  defaultSort: SeriesSortOptions = SeriesSortOptions.Newest
): SeriesSortOptions {
  const qsSort = qsValue(context, sortKey) as SeriesSortOptions
  const validQs = getStrValuesFromEnum(SeriesSortOptions).includes(qsSort)
  return validQs ? qsSort : defaultSort
}

export default Vue.extend({
  name: 'SortSeriesElement',
  components: { SortDropdown },
  props: {
    sortBy: {
      type: String as PropType<SeriesSortOptions | string>,
      default: SeriesSortOptions.Newest,
    },
  },
  computed: {
    options(): FancyDropdownOption[] {
      return getStrValuesFromEnum(SeriesSortOptions).map((o) => {
        return {
          value: o,
          title: this.getDisplayTitle(o),
        }
      })
    },
  },
  methods: {
    getDisplayTitle(value: string): TranslateResult {
      switch (value as SeriesSortOptions) {
        case SeriesSortOptions.Newest:
          return this.$t('Newest Series')
        case SeriesSortOptions.MostSermons:
          return this.$t('Most Sermons')
        case SeriesSortOptions.LeastSermons:
          return this.$t('Least Sermons')
        case SeriesSortOptions.Alphabetical:
          return this.$t('Alphabetical')
        case SeriesSortOptions.Updated:
          return this.$t('Recently Updated')
        default:
          console.debug(
            `Translated title missing for ${value} in Series Sort Dropdown`
          )
          return value
      }
    },
  },
})
