
import Vue, { PropType } from 'vue'
import ScrollList from '~/components/_general/ScrollList.vue'
import ArticleElement from '~/components/article/Element.vue'
import { ArticleFilterParams, DisplayArticleApi } from '~/apiclient/apiarticles'
import { ArticleDisplay } from '~/assets/ts/enums'
import { getListWithPlaceholders } from '~/assets/ts/utils/lists'
import { ValuesMatch } from '~/assets/ts/utils/validation'

export const ArticleListProps = {
  display: {
    type: Number as PropType<ArticleDisplay>,
    default: ArticleDisplay.Standard,
  },
  articleOptions: {
    type: Object as PropType<ArticleFilterParams>,
    required: true,
  },
  placeholders: {
    type: Number,
    default: 5,
  },
  async: {
    type: Boolean,
  },
  allowFiltering: {
    type: Boolean,
  },
  infinite: {
    type: Boolean,
    default: true,
  },
  maxListLength: {
    type: Number,
    default: 500,
  },
  excludedArticles: {
    type: Array as PropType<number[]>,
    default: () => [],
  },
}

export default Vue.extend({
  name: 'ArticleList',
  components: { ArticleElement, ScrollList },
  props: {
    ...ArticleListProps,
  },
  data() {
    return {
      endOfList: false,
      page: 1,
      articles: [] as DisplayArticleApi[],
      fetchedTotalCount: 0,
      fetching: false,
    }
  },
  async fetch() {
    if (this.async) return
    await this.getArticles()
  },
  computed: {
    totalCount(): number {
      return this.fetchedTotalCount - this.excludedArticles.length
    },
    filteredArticles(): DisplayArticleApi[] {
      return this.articles.filter(
        (a) => !this.excludedArticles.includes(a.articleID)
      )
    },
    displayArticles(): (DisplayArticleApi | undefined)[] {
      const articles = this.filteredArticles
      if (!this.placeholders) return articles
      return getListWithPlaceholders(articles, this.placeholders)
    },
  },
  watch: {
    articleOptions: {
      handler(newValue: ArticleFilterParams, oldValue: ArticleFilterParams) {
        if (ValuesMatch(newValue, oldValue)) return
        this.reset()
      },
    },
    fetching() {
      this.$emit('fetching', this.fetching)
    },
    totalCount() {
      this.$emit('count', this.totalCount)
    },
  },
  async mounted() {
    if (!this.async) return
    await this.getArticles()
  },
  methods: {
    scrollList() {
      this.getArticles()
    },
    reset() {
      this.getArticles(true)
    },
    async getArticles(reset = false) {
      if (this.fetching && !reset) return
      this.fetching = true
      if (reset) {
        this.page = 1
      }
      const { results, next, totalCount } = await this.$apiClient.getArticles({
        ...this.articleOptions,
        page: this.page,
        pageSize:
          this.maxListLength && !this.infinite ? this.maxListLength : undefined,
      })
      this.endOfList = !next
      this.fetchedTotalCount = totalCount
      this.$emit('count', this.totalCount)

      this.page += 1
      if (reset) {
        this.articles = results
      } else {
        this.articles = [...this.articles, ...results]
      }
      this.fetching = false
    },
  },
})
