import { apiTimestampToJsDate } from '~/assets/ts/utils/date'
import { Sermon } from '~/models/sermon'
import { UserCommentApi } from '~/apiclient/apicomments'

export interface DisplayUserApi {
  user_id: number
  username: string
  profile_picture?: string
  display_name?: string
  display_location?: string
  date_added: number
}

export class DisplayUser {
  id: number
  username: string
  profilePictureUrl?: string
  displayName?: string
  displayLocation?: string
  signupDate: Date

  constructor(displayUser: DisplayUserApi) {
    this.id = displayUser.user_id
    this.username = displayUser.username
    this.profilePictureUrl = displayUser.profile_picture || undefined
    this.displayName = displayUser.display_name || undefined
    this.displayLocation = displayUser.display_location || undefined
    this.signupDate = apiTimestampToJsDate(displayUser.date_added)
  }

  get DisplayName(): string {
    return this.displayName || this.username
  }

  ProfilePicture(size: number): string | undefined {
    if (!this.profilePictureUrl) return undefined
    return `https://vps.sermonaudio.com/resize_image/images/user/${size}/${size}/${this.profilePictureUrl}?webp=true`
  }
}

export class UserComment {
  id: number
  title: string
  type: string
  commenterLocation?: string
  commenterName?: string
  commenterUrl?: string
  ranking: number
  text?: string
  commentDateTime: Date
  sermon?: Sermon
  displayUser?: DisplayUser
  pendingReview: boolean

  constructor(comment: UserCommentApi) {
    this.id = comment.commentID
    this.title = comment.commentTitle
    this.type = comment.commentType
    this.commenterLocation = comment.commenterLocation || undefined
    this.commenterName = comment.commenterName || undefined
    this.commenterUrl = comment.commenterURL || undefined
    this.ranking = comment.ranking
    this.text = comment.text || undefined
    this.commentDateTime = apiTimestampToJsDate(comment.timestamp)
    this.sermon = new Sermon(comment.sermon)
    this.displayUser = comment.displayUser
      ? new DisplayUser(comment.displayUser)
      : undefined
    this.pendingReview = comment.pendingReview
  }

  get displayLocation(): string {
    if (this.commenterLocation) return this.commenterLocation
    return this.displayUser?.displayLocation ?? ''
  }

  get displayName(): string {
    if (this.commenterName) return this.commenterName
    return this.displayUser?.DisplayName ?? ''
  }
}
