import { BroadcasterNoticesApi } from '~/apiclient/apibroadcasters'
import { apiTimestampToJsDate } from '~/assets/ts/utils/date'
import { Broadcaster } from '~/models/broadcaster'

export class Notice {
  body: string
  disabled: boolean
  id: number
  time: Date
  title: string
  broadcaster: Broadcaster

  constructor(notice: BroadcasterNoticesApi) {
    this.body = notice.body
    this.disabled = notice.disabled
    this.id = notice.id
    this.time = apiTimestampToJsDate(notice.timestamp)
    this.title = notice.title
    this.broadcaster = new Broadcaster(notice.broadcaster)
  }

  get recent() {
    const recentDays = 14
    const twoWeeksInMilliseconds = recentDays * 24 * 60 * 60 * 1000
    return new Date().getTime() - this.time.getTime() < twoWeeksInMilliseconds
  }
}
