
import Vue, { PropType } from 'vue'
import CoreElementWrapper from '~/components/_general/CoreElementWrapper.vue'
import { DisplayArticleApi } from '~/apiclient/apiarticles'
import { ArticleDisplay, DateFormat } from '~/assets/ts/enums'
import ArticleThumbnail from '~/components/article/Thumbnail.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import { apiDateToJsDate, localizeDateTime } from '~/assets/ts/utils/date'
import { GetArticleUrl, siteSpeakerUrl } from '~/assets/ts/utils/urls'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import { Speaker } from '~/models/speaker'
import { stripMarkdown } from '~/assets/ts/markdown/renderer'
import SaLinkOrDjango from '~/components/_general/SaLinkOrDjango.vue'
import SaLinkOrHref from '~/components/_general/SaLinkOrHref.vue'

export default Vue.extend({
  name: 'ArticleElement',
  components: {
    SaLinkOrHref,
    InlineIcon,
    LoadingElement,
    ArticleThumbnail,
    CoreElementWrapper,
  },
  props: {
    article: {
      type: Object as PropType<DisplayArticleApi>,
      default: undefined,
    },
    display: {
      type: Number as PropType<ArticleDisplay>,
      default: ArticleDisplay.Standard,
    },
    listDivider: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    SaLinkOrDjango() {
      return SaLinkOrDjango
    },
    dashboard(): boolean {
      return [
        ArticleDisplay.Dashboard,
        ArticleDisplay.DjangoDashboard,
      ].includes(this.display)
    },
    django(): boolean {
      return this.display === ArticleDisplay.DjangoDashboard
    },
    news(): boolean {
      return this.display === ArticleDisplay.News
    },
    releaseNote(): boolean {
      return this.display === ArticleDisplay.ReleaseNotes
    },
    sa(): boolean {
      return this.news || this.releaseNote
    },
    speaker(): Speaker | undefined {
      const speaker = this.article?.speaker
      if (!speaker) return undefined
      return new Speaker(speaker)
    },
    speakerName(): string {
      return this.speaker?.displayName ?? ''
    },
    speakerUrl(): string {
      if (!this.speaker) return ''
      return `${siteSpeakerUrl(this.speaker)}/articles`
    },
    preview(): string {
      if (!this.article?.preview) return ''
      return stripMarkdown(this.article.preview)
    },
    articleUrl(): string {
      if (!this.article) return ''
      return GetArticleUrl(this.display, this.article)
    },
    articleDate(): string {
      if (!this.article) return ''
      const date = apiDateToJsDate(this.article.articleDate) as Date
      return localizeDateTime(date, DateFormat.CompressedDate)
    },
    category(): string {
      if (this.sa) return ''
      return this.article?.categoryDescription ?? ''
    },
    commentCount(): number {
      return 0
      // TODO: Add this once we have comments
      // return this.article.commentCount ?? 0
    },
  },
  methods: {},
})
