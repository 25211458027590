
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'SiteFieldSection',
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    description: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    /** Shows (Optional) after title */
    optional: {
      type: Boolean,
    },
    /** Spaces out items in slot by a standard amount */
    slotSpacing: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    displayTitle(): TranslateResult {
      if (!this.optional) return this.title
      return `${this.title} (${this.$t('Optional')})`
    },
  },
})
