
import Vue from 'vue'
import { LatLngLiteral, Map, MapOptions } from 'leaflet'
import { MapCoreProps, MapFlyTo } from '~/models/map'
import 'leaflet/dist/leaflet.css'
import Poller from '~/components/_general/Poller.vue'

let LMap
if (process.client) {
  require('leaflet')
  ;({ LMap } = require('vue2-leaflet/dist/vue2-leaflet.min'))
}

export default Vue.extend({
  name: 'MapCore',
  components: { Poller, LMap },
  inheritAttrs: false,
  props: MapCoreProps,
  data() {
    return {
      zoom: 9,
      flyToTimeout: 0,
      animating: false,
      resizeDebounceTimer: 500,
      resizeTimeout: 0,
      leafletApi: undefined as Map | undefined,
    }
  },
  computed: {
    actualZoom(): number {
      return (this.leafletApi as Record<string, any>)._zoom
    },
    actualLocation(): LatLngLiteral {
      return (this.leafletApi as Map).getCenter()
    },
    mapOptions(): MapOptions {
      return {
        scrollWheelZoom: this.zoomControls,
        zoomControl: this.zoomControls,
        keyboard: this.zoomControls,
        trackResize: true,
        doubleClickZoom: this.zoomControls,
        dragging: this.zoomControls,
        zoomSnap: this.zoomSnap,
        zoomDelta: this.zoomDelta,
        zoom: this.zoom,
        // maxBounds: [
        //   [-90, 180], // south-west
        //   [90, -180], // north-east
        // ],
        ...this.additionalMapOptions,
      }
    },
    zoomControls(): boolean {
      return this.minZoom !== this.maxZoom && this.showZoomControls
    },
  },
  watch: {
    flyTo() {
      if (!this.flyTo) return
      this.animateFlyTo(this.flyTo)
    },
    latLng() {
      if (!this.latLng) return
      this.moveTo(this.latLng)
    },
  },
  destroyed() {
    this.animating = false
    clearTimeout(this.flyToTimeout)
    clearTimeout(this.resizeTimeout)
  },
  mounted() {
    this.zoom = this.defaultZoom
  },
  methods: {
    // https://leafletjs.com/reference.html#map
    animateFlyTo(flyTo: MapFlyTo) {
      if (this.animating) return
      clearTimeout(this.flyToTimeout)
      this.leafletApi?.flyTo(flyTo.latLng, flyTo.zoom, {
        duration: flyTo.duration,
        easeLinearity: flyTo.linearity,
      })
      this.flyToTimeout = window.setTimeout(() => {
        this.animating = false
        this.$emit('animationEnd')
        this.zoomTo(flyTo?.zoom, false)
        this.moveTo(flyTo?.latLng, false)
        this.updateFlyingEvents()
      }, flyTo.duration * 1000)
      this.animating = true
      this.$emit('animationStart')
    },
    centerChangeEvent(center: LatLngLiteral) {
      this.$emit('move', center)
    },
    zoomTo(zoomLevel: number, animate = true) {
      if (!this.leafletApi) return
      this.leafletApi.setZoom(zoomLevel, {
        animate,
      })
    },
    moveTo(latLng: LatLngLiteral, animate = true) {
      if (!this.leafletApi) return
      this.leafletApi.setView(latLng, undefined, {
        animate,
      })
    },
    readyEvent(obj: Map) {
      this.leafletApi = obj
      this.zoomTo(this.zoom, false)
      this.moveTo(this.latLng, false)
      this.$emit('ready', obj)
    },
    zoomEvent(level: number) {
      this.$emit('zoom', level)
    },
    updateFlyingEvents() {
      this.$emit('zoom', this.actualZoom)
      this.$emit('move', this.actualLocation)
    },
    resize() {
      if (this.fixedSize) return
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = window.setTimeout(() => {
        if (!this.leafletApi) return
        this.leafletApi.invalidateSize(false)
      }, this.resizeDebounceTimer)
    },
  },
})
