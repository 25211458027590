
import Vue, { PropType } from 'vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SiteSeeAll from '~/components/site/SeeAll.vue'
import { siteBroadcasterUrl } from '~/assets/ts/utils/urls'
import { Broadcaster } from '~/models/broadcaster'

export default Vue.extend({
  name: 'BroadcasterRecentNotices',
  components: { SiteSeeAll, InlineIcon },
  props: {
    recentCount: {
      type: Number,
      default: 0,
    },
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      required: true,
    },
  },
  computed: {
    hasMore(): boolean {
      return this.recentCount > 0
    },
    seeAllUrl(): string {
      return siteBroadcasterUrl(this.broadcaster, 'notices')
    },
  },
})
