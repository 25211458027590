export function readImage(
  image: File | Blob,
  callback: (dataUrl: string) => void
) {
  const reader = new FileReader()
  reader.onload = (event) => {
    if (event.target) {
      const result = event.target.result as string
      callback(result)
    }
  }
  reader.readAsDataURL(image)
}

export function formatResolution(width: number, height: number): string {
  if (!width || !height) return ''
  return `${Math.round(width)} x ${Math.round(height)}px`
}

export function webpUrl(
  url: string,
  resize: PartialRecord<'width' | 'height', number> = {}
) {
  const urlObj = new URL(url)
  const params = new URLSearchParams(urlObj.search)
  params.set('webp', 'true')

  if (resize.width) {
    params.set('width', resize.width.toString())
  }
  if (resize.height) {
    params.set('width', resize.height.toString())
  }

  urlObj.search = params.toString()
  return urlObj.toString()
}
