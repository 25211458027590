
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import { Broadcaster } from '~/models/broadcaster'
import BroadcasterElement from '~/components/_general/BroadcasterElement.vue'
import { BroadcasterDisplay } from '~/assets/ts/enums'

export default Vue.extend({
  name: 'BroadcasterActionModal',
  components: {
    BroadcasterElement,
    HorizontalRule,
  },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
    modal: {
      type: Boolean,
      default: true,
    },
    element: {
      type: String,
      default: 'div',
    },
    title: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
  },
  computed: {
    broadcasterDisplay(): BroadcasterDisplay {
      return BroadcasterDisplay.Standard
    },
  },
})
