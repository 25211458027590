
import Vue, { PropType } from 'vue'
import { SiteAlertModal } from '~/assets/ts/utils/site'
import BroadcasterDonateSelections from '~/components/broadcaster/DonateSelections.vue'
import { AlertButton } from '~/models/generic/alert-button'
import { Broadcaster } from '~/models/broadcaster'
import { broadcasterDonateUrl } from '~/assets/ts/utils/urls'
import { BroadcasterDonationOption } from '~/apiclient/apibroadcasters'

export default Vue.extend({
  name: 'BroadcasterDonateButton',
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
    overrideDonationOptions: {
      type: Array as PropType<BroadcasterDonationOption[]>,
      default: undefined,
    },
  },
  computed: {
    url(): string {
      return broadcasterDonateUrl(this.broadcaster.id)
    },
  },
  methods: {
    openModal() {
      SiteAlertModal(this, {
        component: BroadcasterDonateSelections,
        componentProps: {
          broadcaster: this.broadcaster,
          overrideDonationOptions: this.overrideDonationOptions,
        },
        neutral: new AlertButton({
          text: this.$t('Close'),
        }),
      })
    },
  },
})
