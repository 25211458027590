
import Vue, { PropType } from 'vue'
import { LatLngLiteral } from 'leaflet'

let LMarker, LIcon
if (process.client) {
  ;({ LMarker, LIcon } = require('vue2-leaflet/dist/vue2-leaflet.min'))
  // https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
  // Dealing with missing marker image console logs.
  // Replacing the stock image with a 35 byte 1x1 gif to minimize overhead.
  const leaflet = require('leaflet')
  delete (leaflet.Icon.Default.prototype as Record<string, any>)._getIconUrl
  leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('@/assets/images/tiny.gif'),
    iconUrl: require('@/assets/images/tiny.gif'),
    shadowUrl: require('@/assets/images/tiny.gif'),
  })
}

export default Vue.extend({
  name: 'MapMarker',
  components: { LMarker, LIcon },
  props: {
    latLng: {
      type: Object as PropType<LatLngLiteral>,
      required: true,
    },
    anchor: {
      type: Array,
      default: undefined,
    },
    size: {
      type: Array,
      default: undefined,
    },
    iconClasses: {
      type: String,
      default: 'default-marker-icon-size',
    },
  },
})
