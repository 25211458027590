
import Vue, { PropType } from 'vue'
import { Broadcaster } from '~/models/broadcaster'
import BroadcasterActionModal from '~/components/broadcaster/ActionModal.vue'
import SiteButton from '~/components/site/Button.vue'
import { FullNameDefaults } from '~/assets/ts/utils/input'
import SiteFieldSection from '~/components/site/FieldSection.vue'
import { User } from '~/models/users/user'
import SiteUserVerificationWarning from '~/components/site/user/VerificationWarning.vue'
import SaLink from '~/components/_general/SaLink.vue'
import { siteLoginUrl } from '~/assets/ts/utils/urls'
import { waitSeconds } from '~/assets/ts/utils/misc'
import {
  ToastApiError,
  ToastShortDuration,
  ToastSuccess,
} from '~/assets/ts/utils/toast'
import SiteButtonSpinner from '~/components/site/ButtonSpinner.vue'
import { closePopup, isInPopup } from '~/assets/ts/utils/iframe'

export default Vue.extend({
  name: 'BroadcasterContactForm',
  components: {
    SiteButtonSpinner,
    SaLink,
    SiteUserVerificationWarning,
    SiteFieldSection,
    SiteButton,
    BroadcasterActionModal,
  },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
    modal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const user = this.$users.user
    return {
      message: '',
      fullName: user?.DisplayName ?? '',
      sending: false,
      sent: false,
    }
  },
  computed: {
    email(): string {
      return this.user?.email ?? ''
    },
    disabled(): boolean {
      return this.sending || this.sent
    },
    user(): User | undefined {
      return this.$users.user
    },
    verified(): boolean {
      return !!this.user?.verified
    },
    FullNameDefaults() {
      return FullNameDefaults
    },
    loginUrl(): string {
      return siteLoginUrl(this)
    },
    createUrl(): string {
      return siteLoginUrl(this, true)
    },
  },
  methods: {
    close() {
      this.$emit('close')
      closePopup()
    },
    async sendMessage() {
      this.sending = true
      await this.$apiClient
        .sendBroadcasterMessage(this.broadcaster.id, {
          content: this.message,
          name: this.fullName,
        })
        .then(async () => {
          ToastSuccess(this.$t('Email Sent!'), ToastShortDuration)
          this.sent = true
          if (isInPopup()) {
            await waitSeconds(1)
          }
          this.sending = false
          this.close()
        })
        .catch((e) => {
          ToastApiError(e, this)
          this.sending = false
        })
    },
  },
})
