
import Vue, { PropType } from 'vue'
import { Speaker } from '~/models/speaker'
import { SpeakerDisplay } from '~/assets/ts/enums'
import FollowButton from '~/components/_general/FollowButton.vue'
import { siteSpeakerSermonsUrl, siteSpeakerUrl } from '~/assets/ts/utils/urls'
import { Broadcaster } from '~/models/broadcaster'
import CoreElementWrapper from '~/components/_general/CoreElementWrapper.vue'
import SpeakerImage from '~/components/_general/SpeakerImage.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import VerticalAlign from '~/components/_general/VerticalAlign.vue'
import SaLink from '~/components/_general/SaLink.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import { convertLineBreaks, truncateString } from '~/assets/ts/utils/strings'
import { SermonFilterCategories } from '~/assets/ts/utils/params'

export default Vue.extend({
  name: 'SpeakerElement',
  components: {
    MarkdownElement,
    SaLink,
    VerticalAlign,
    LoadingElement,
    SpeakerImage,
    CoreElementWrapper,
    FollowButton,
  },
  props: {
    speaker: {
      type: Object as PropType<Speaker>,
      default: undefined,
    },
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
    display: {
      type: Number as PropType<SpeakerDisplay>,
      default: SpeakerDisplay.Standard,
    },
    title: {
      type: String,
      default: '',
    },
    includeFollowButton: {
      type: Boolean,
    },
    includeInfoFollowButton: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
    },
    reverseNames: {
      type: Boolean,
    },
    removeBioLineBreaks: {
      type: Boolean,
    },
    smallerPhoto: {
      type: Boolean,
    },
    urlSubpage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    shareTitle(): string {
      return this.speaker?.shareTitle ?? ''
    },
    displayName(): string {
      if (this.speaker) {
        return this.reverseNames
          ? this.speaker.sortName
          : this.speaker.displayName
      } else {
        return ''
      }
    },
    bio(): string | undefined {
      if (!this.speaker || !this.speaker.bio) {
        return undefined
      }

      return truncateString(
        this.removeBioLineBreaks
          ? convertLineBreaks(this.speaker.bio, ' ')
          : this.speaker.bio,
        300,
        true
      )
    },
    sermonCount(): string {
      if (!this.speaker || this.speaker.sermonCount === 0) return ''
      return this.$tc('{n} Sermon | {n} Sermons', this.speaker.sermonCount, {
        n: this.speaker.sermonCountDisplay,
      })
    },
    speakerUrl(): string {
      if (this.broadcaster) {
        return siteSpeakerSermonsUrl(this.speaker, [
          {
            key: SermonFilterCategories.Broadcaster,
            value: this.broadcaster.id,
          },
        ])
      }
      return `${siteSpeakerUrl(this.speaker)}${this.urlSubpage}`
    },
    infoColumn(): boolean {
      return this.display === SpeakerDisplay.Info
    },
    expanded(): boolean {
      return this.display === SpeakerDisplay.Expanded
    },
    standard(): boolean {
      return (
        this.display === SpeakerDisplay.Standard ||
        this.display === SpeakerDisplay.StandardWrap
      )
    },
    micro(): boolean {
      return this.display === SpeakerDisplay.Micro
    },
    wrap(): boolean {
      return (
        this.display === SpeakerDisplay.StandardWrap ||
        this.display === SpeakerDisplay.Expanded
      )
    },
    speakerImageSize(): number {
      if (this.expanded && !this.small) {
        return this.smallerPhoto ? 90 : 124
      } else if (this.micro) {
        return 20
      } else if (this.infoColumn) {
        return 72
      } else {
        return 40
      }
    },
    speakerImageWidth(): string {
      if (this.expanded && !this.small) {
        if (this.smallerPhoto) return 'text-10 xs:text-20 sm:text-[5.5rem]'
        return 'text-10 xs:text-20 sm:text-28'
      } else if (this.micro) {
        return 'text-5'
      } else if (this.infoColumn) {
        if (this.smallerPhoto) return 'text-12'
        return 'text-16' // 'w-12'
      } else {
        return 'text-10'
      }
    },
  },
  mounted() {
    this.mounted = true
  },
})
