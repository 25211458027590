import { LatLngLiteral } from 'leaflet'
import { toLatLng } from '~/models/map'
import { Speaker } from '~/models/speaker'
import { WebcastApi } from '~/apiclient/apiwebcasts'
import {
  apiTimestampToJsDate,
  jsDateToApiTimestamp,
  secondsToHhMmSs,
} from '~/assets/ts/utils/date'
import { openPopoutWindow, resizableUrl } from '~/assets/ts/utils/misc'
import { Broadcaster } from '~/models/broadcaster'
import { distanceDisplay, latLngDistance } from '~/assets/ts/utils/locations'
import { siteWebcastUrl } from '~/assets/ts/utils/urls'

export class Webcast {
  id: number
  broadcasterID: string
  broadcasterLocation: string
  broadcasterMinister: string
  displayName: string
  followedBroadcaster: boolean
  group?: string
  miles?: number
  latLng: LatLngLiteral
  peakListenerCount: number
  previewImageUrl?: string
  resizablePreviewImageUrl?: string
  startTime: number
  totalTuneInCount: number
  videoStreamUrl: string
  previewStreamUrl: string
  audioStreamUrl: string
  title?: string
  description?: string
  speakerName?: string
  speaker?: Speaker
  endTime?: number
  broadcaster: Broadcaster
  isLive: boolean
  eventStreamUrl: string
  audioEventStreamUrl: string

  constructor(webcast: WebcastApi) {
    this.id = webcast.webcastID
    this.broadcasterID = webcast.broadcasterID
    this.broadcasterLocation = webcast.broadcasterLocation
    this.broadcasterMinister = webcast.broadcasterMinister
    this.broadcaster = new Broadcaster({
      type: 'webcastBroadcaster',
      broadcasterID: webcast.broadcasterID,
      imageURL: webcast.broadcasterImageURL,
      imageURLResizable: webcast.broadcasterImageURLResizable,
      location: webcast.broadcasterLocation,
      minister: webcast.broadcasterMinister,
      denomination: webcast.group,
      displayName: webcast.displayName,
    })
    this.displayName = webcast.displayName
    this.followedBroadcaster = webcast.followedBroadcaster
    this.group = webcast.group
    this.miles = webcast.miles
    this.peakListenerCount = webcast.peakListenerCount
    this.previewImageUrl = webcast.previewImageURL
    this.resizablePreviewImageUrl = webcast.resizablePreviewImageURL
    this.startTime = webcast.startTime
    this.totalTuneInCount = webcast.totalTuneInCount
    this.videoStreamUrl = webcast.videoStreamURL
    this.previewStreamUrl = this.videoStreamUrl
      ? this.videoStreamUrl.replace('webcast-cloud', 'webcast-cloud-cf')
      : ''
    this.audioStreamUrl = webcast.audioStreamURL
    this.latLng = toLatLng(webcast.broadcasterLat, webcast.broadcasterLong)
    this.title = webcast.webcastTitle ? webcast.webcastTitle : undefined
    this.description = webcast.webcastDescription
    this.speakerName = webcast.webcastSpeakerName
    this.eventStreamUrl = webcast.eventStreamURL
    this.audioEventStreamUrl = webcast.audioEventStreamURL
    this.speaker = webcast.webcastSpeaker
      ? new Speaker(webcast.webcastSpeaker)
      : undefined
    this.endTime = webcast.endTime
    if (webcast.isLive !== undefined) {
      this.isLive = webcast.isLive
    } else if (!webcast.endTime) {
      this.isLive = true
    } else {
      // in the case of recent webcasts, if they are currently live, the endTime
      // will be equal to the api timestamp at the time it was returned
      const ended = jsDateToApiTimestamp(new Date()) - webcast.endTime
      this.isLive = ended < 60
    }
  }

  get AudioOnly(): boolean {
    return !this.videoStreamUrl
  }

  ResizableImage(width: number): string | undefined {
    const url = this.resizablePreviewImageUrl
    if (!url) return undefined
    const height = Math.round(width * 0.5625)
    return resizableUrl(url, width, height)
    // if we want this image to not be cached, use this
    // return `${resizableUrl(url, width, height)}&t=${new Date().getTime()}`
  }

  get StartDate(): Date {
    return apiTimestampToJsDate(this.startTime)
  }

  get EndDate(): Date | undefined {
    return this.endTime ? apiTimestampToJsDate(this.endTime) : undefined
  }

  Title(context: Record<string, any>): string {
    if (this.title) return this.title
    return this.isLive
      ? context.$t('Live Webcast').toString()
      : context.$t('Previously Live Webcast').toString()
  }

  ShareTitle(context: Record<string, any>): string {
    return this.Title(context)
  }

  get SpeakerName(): string {
    return this.speakerName ?? this.broadcasterMinister
  }

  get Duration(): number | undefined {
    if (!this.endTime) return undefined
    return this.endTime - this.startTime
  }

  get DurationString(): string {
    if (!this.Duration) return ''
    return secondsToHhMmSs(this.Duration)
  }

  get Url(): string {
    return siteWebcastUrl(this.broadcasterID)
  }

  get SoloUrl(): string {
    return `${this.broadcaster.SoloUrl}webcast/`
  }

  distance(latLng: LatLngLiteral, miles = true): number {
    return latLngDistance(this.latLng, latLng, miles)
  }

  distanceDisplay(latLng: LatLngLiteral, miles = true): string {
    return distanceDisplay(this.distance(latLng, miles), miles)
  }

  openPopup(url: string) {
    openPopoutWindow(url)
  }

  matchesSearchTerm(searchTerm: string): boolean {
    const search = searchTerm.toLowerCase()
    if (this.broadcaster.matchesSearchTerm(search)) return true
    if (this.title?.toLowerCase().includes(search)) return true
    if (this.description?.toLowerCase().includes(search)) return true
    if (this.speaker) {
      if (this.speaker.matchesSearchTerm(search)) return true
    } else if (this.speakerName) {
      if (this.speakerName.toLowerCase().includes(search)) return true
    }
    return false
  }
}
