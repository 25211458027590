
import Vue, { PropType } from 'vue'
import { Notice } from '~/models/notice'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import { localizeDateTime } from '~/assets/ts/utils/date'
import { DateFormat } from '~/assets/ts/enums'

export default Vue.extend({
  name: 'BroadcasterNotice',
  components: { MarkdownElement, HorizontalRule },
  props: {
    notice: {
      type: Object as PropType<Notice | undefined>,
      default: undefined,
    },
    showLabel: {
      type: Boolean,
    },
  },
  computed: {
    bgClass(): string {
      if (!this.notice) return 'loading-placeholder w-full min-h-[8rem]'
      return this.recent
        ? 'bg-blue-light dark:bg-blue-navy'
        : 'bg-gray-100 dark:bg-gray-800'
    },
    recent(): boolean {
      return this.notice?.recent ?? false
    },
    time(): string {
      if (!this.notice) return ''
      return localizeDateTime(this.notice.time, DateFormat.DateTime)
    },
  },
})
