
import Vue, { PropType } from 'vue'
import { Webcast } from '~/models/webcast'
import { siteWebcastUrl } from '~/assets/ts/utils/urls'
import VideoThumbnail from '~/components/_general/VideoThumbnail.vue'
import { LoadingTypes } from '~/components/_general/SaImage.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'

export default Vue.extend({
  name: 'WebcastThumbnail',
  components: { VideoThumbnail, InlineIcon },
  props: {
    webcast: {
      type: Object as PropType<Webcast>,
      default: undefined,
    },
    hoverPreview: {
      type: Boolean,
      default: true,
    },
    preview: {
      type: Boolean,
    },
    hideCount: {
      type: Boolean,
    },
    /** Rounded Tailwind Class */
    rounded: {
      type: String,
      default: 'rounded',
      validator(value: string) {
        return value.includes('rounded')
      },
    },
    loading: {
      type: String as PropType<LoadingTypes>,
      default: 'lazy',
    },
    volumeOverride: {
      type: Number,
      default: 0,
      validator(value: number) {
        return value >= 0 && value <= 100
      },
    },
  },
  computed: {
    hasVideo(): boolean {
      return !this.webcast?.AudioOnly
    },
    viewersCount(): number {
      return this.webcast?.totalTuneInCount ?? 0
    },
    imageUrl(): string {
      return this.webcast?.ResizableImage(360) ?? ''
    },
    liveUrl(): string {
      return siteWebcastUrl(this.webcast?.broadcasterID)
    },
  },
})
