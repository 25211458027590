
import Vue, { PropType } from 'vue'
import SortSeriesElement, {
  SeriesSortOptions,
} from '~/components/sort/SeriesElement.vue'
import ListHeaderWrapper, {
  ListHeaderWrapperProps,
} from '~/components/_general/ListHeaderWrapper.vue'
import SearchInput from '~/components/_general/SearchInput.vue'
import { qsValue } from '~/assets/ts/utils/params'

export default Vue.extend({
  name: 'SiteSeriesListHeader',
  components: {
    SearchInput,
    ListHeaderWrapper,
    SortSeriesElement,
  },
  props: {
    ...ListHeaderWrapperProps,
    sort: {
      type: String as PropType<SeriesSortOptions>,
      default: SeriesSortOptions.Newest,
    },
  },
  data() {
    return {
      searchQuery: qsValue(this, 'q') ?? '',
    }
  },
  methods: {
    setSearch() {
      this.$emit('search', this.searchQuery)
    },
    sortChanged(sort: SeriesSortOptions) {
      this.$emit('sort', sort)
    },
  },
})
