
import Vue, { PropType } from 'vue'
import { Series } from '~/models/series'
import { siteSeriesUrl } from '~/assets/ts/utils/urls'
import SaIcon from '~/components/_general/SaIcon.vue'
import SaImage from '~/components/_general/SaImage.vue'
import SaLink from '~/components/_general/SaLink.vue'

export default Vue.extend({
  name: 'SeriesImage',
  components: { SaLink, SaImage, SaIcon },
  props: {
    series: {
      type: Object as PropType<Series>,
      default: undefined,
    },
    size: {
      type: Number,
      default: 512,
    },
    coloredBg: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    url(): string | undefined {
      return this.series?.albumArt(this.size)
    },
    seriesUrl(): string {
      if (!this.series) return ''
      return siteSeriesUrl(this.series)
    },
  },
})
