import { LatLngLiteral } from 'leaflet'

export function latLngDistance(
  locationA: LatLngLiteral,
  locationB: LatLngLiteral,
  miles = false
) {
  const earthRadius = 6371 // Earth's radius in kilometers

  // Convert latitude and longitude values from degrees to radians
  const latARad = (locationA.lat * Math.PI) / 180
  const lngARad = (locationA.lng * Math.PI) / 180
  const latB = (locationB.lat * Math.PI) / 180
  const lng2Rad = (locationB.lng * Math.PI) / 180

  // Calculate differences between latitudes and lnggitudes
  const latDiff = latB - latARad
  const lngDiff = lng2Rad - lngARad

  // Haversine formula
  const a =
    Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
    Math.cos(latARad) *
      Math.cos(latB) *
      Math.sin(lngDiff / 2) *
      Math.sin(lngDiff / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  // Calculate distance in kilometers
  const km = earthRadius * c
  return miles ? kilometersToMiles(km) : km
}

export function kilometersToMiles(kilometers: number) {
  return kilometers * 0.621371
}

export function distanceDisplay(distance: number, miles = true): string {
  const dist = Math.ceil(distance).toLocaleString()
  return `${dist}${miles ? 'mi' : 'km'}`
}
