import { PropType } from 'vue'
import { LatLngLiteral } from 'leaflet'
import { Feature } from 'geojson'

export function toLatLng(lat: number, lng: number) {
  return {
    lat,
    lng,
  } as LatLngLiteral
}

export const latLngLocations = {
  USA: toLatLng(39.809734, -98.55562),
  International: toLatLng(39.809734, 0),
  Asia: toLatLng(30.297, 95.625),
  Africa: toLatLng(1.4061, 16.4356),
  SouthAmerica: toLatLng(-27.8391, -60.9082),
  Australia: toLatLng(-25.2744, 133.7751),
  Canada: toLatLng(59.9, -111.1597),
  England: toLatLng(55.3781, -3.436),
  Europe: toLatLng(55.628, 25.4883),
  Russia: toLatLng(66.653, 121.7944),
  MiddleEast: toLatLng(24.2069, 45.5274),
  Alaska: toLatLng(64.9608, -158.7085),
  Vault: toLatLng(34.8726, -82.3625),
}

export enum MapGeoJSONUrl {
  /**
   * Use this _very_ carefully. This is a very large full resolution file of the entire globe
   *
   * This was downloaded from here: https://github.com/nvkelso/natural-earth-vector/tree/master/geojson
   * Then using a text editor all text feature attributes were removed
   *
   * */
  Full = 'ne_10m_admin_0_countries_coords_only',
  /** Heavily simplified/dissolved map of the entire globe primarily for use with the Video Wall
   *
   * All simplification was done with https://mapshaper.org/
   *
   * 1. Used MapShaper to remove the hanging portion of Russia in the top left as well as Antarctica.
   * 2. Used MapShaper to merge all countries into 1 shape with the --dissolve command (effectively removing a lot of unnecessary internal lines)
   * 3. Used MapShaper to simplify the shape down to 30% with "prevent shape removal" turned on and using the "Visvalingam/effective area" method.
   *
   * */
  VideoWall10m = 'ne_10m_admin_simplified',
  /**
   * Lightly simplified version of the 50m map from here: https://github.com/nvkelso/natural-earth-vector/tree/master/geojson
   * Modified in the same way as the 10m version, but without the 3rd "simplify" step
   *
   */
  VideoWall50m = 'ne_50m_admin_simplified',
}

export interface MapGeoJSONFile {
  features: Feature[]
  type: string
}

export interface MapFlyToParams {
  /** seconds */
  duration?: number
  latLng: LatLngLiteral
  zoom: number
  /** 0 = smooth; 1 = linear */
  linearity?: number
}

export class MapFlyTo implements MapFlyToParams {
  duration: number
  latLng: LatLngLiteral
  zoom: number
  linearity: number
  constructor(params: MapFlyToParams) {
    this.duration = params.duration || 5
    this.latLng = params.latLng
    this.zoom = params.zoom
    this.linearity = params.linearity !== undefined ? params.linearity : 0.25
  }
}

export const MapCoreProps = {
  hideAttribution: {
    type: Boolean,
  },
  defaultZoom: {
    type: Number,
    default: 9,
    validator: (value: number) => {
      return value >= 1 && value <= 18
    },
  },
  minZoom: {
    type: Number,
    default: 4,
    validator: (value: number) => {
      return value >= 1 && value <= 18
    },
  },
  maxZoom: {
    type: Number,
    default: 18,
    validator: (value: number) => {
      return value >= 1 && value <= 18
    },
  },
  latLng: {
    type: Object as PropType<LatLngLiteral>,
    required: true,
  },
  mapUrl: {
    type: String,
    default: '',
  },
  fixedSize: {
    type: Boolean,
    default: false,
  },
  showZoomControls: {
    type: Boolean,
    default: false,
  },
  zoomSnap: {
    type: Number,
    default: 1,
    validator: (value: number) => {
      return value >= 0 && value <= 18
    },
  },
  zoomDelta: {
    type: Number,
    default: 1,
    validator: (value: number) => {
      return value >= 0 && value <= 18
    },
  },
  zoomUpdateFrequency: {
    type: Number,
    default: 0.1,
  },
  flyTo: {
    type: Object as PropType<MapFlyTo>,
    default: undefined,
  },
  additionalMapOptions: {
    type: Object as PropType<Record<any, any>>,
    default: () => {
      return {}
    },
  },
}
