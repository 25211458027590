
import Vue, { PropType } from 'vue'
import { Broadcaster } from '~/models/broadcaster'
import BroadcasterHome from '~/components/broadcaster/Home.vue'

export default Vue.extend({
  components: { BroadcasterHome },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      required: true,
    },
  },
})
