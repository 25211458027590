
import Vue, { PropType } from 'vue'
import FancyDropdown, {
  FancyDropdownOption,
} from '~/components/_general/FancyDropdown.vue'

export default Vue.extend({
  name: 'SortDropdown',
  components: { FancyDropdown },
  model: {
    prop: 'currentValue',
    event: 'change',
  },
  props: {
    currentValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<FancyDropdownOption[]>,
      required: true,
    },
  },
})
