
import Vue, { PropType } from 'vue'
import { SiteAlertModal } from '~/assets/ts/utils/site'
import BroadcasterContactForm from '~/components/broadcaster/ContactForm.vue'
import { Broadcaster } from '~/models/broadcaster'
import { broadcasterContactUrl } from '~/assets/ts/utils/urls'

export default Vue.extend({
  name: 'BroadcasterContactButton',
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
  },
  computed: {
    url(): string {
      return broadcasterContactUrl(this.broadcaster.id)
    },
  },
  methods: {
    openModal() {
      SiteAlertModal(this, {
        component: BroadcasterContactForm,
        componentProps: {
          broadcaster: this.broadcaster,
        },
      })
    },
  },
})
