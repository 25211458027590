import { render, staticRenderFns } from "./PinnedArticles.vue?vue&type=template&id=7f28dd4e&scoped=true"
import script from "./PinnedArticles.vue?vue&type=script&lang=ts"
export * from "./PinnedArticles.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f28dd4e",
  null
  
)

export default component.exports