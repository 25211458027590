
import Vue, { PropType } from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import NavigationTab from '~/components/_general/NavigationTab.vue'
import { qsBool, updateQs } from '~/assets/ts/utils/params'
import { SaIconsType } from '~/assets/ts/types/icons'

export const LayoutSwitcherProps = {
  customQ: {
    type: String,
    default: 'alt-layout',
  },
  icon1: {
    type: String as PropType<SaIconsType>,
    default: 'grid-2',
  },
  icon2: {
    type: String as PropType<SaIconsType>,
    default: 'thumb-list',
  },
}

export default Vue.extend({
  name: 'SiteLayoutSwitcher',
  components: { NavigationTab, SaIcon },
  props: {
    ...LayoutSwitcherProps,
  },
  data() {
    return {
      altSelection: qsBool(this, this.customQ),
    }
  },
  methods: {
    setAltSelection(altSelection: boolean) {
      this.altSelection = altSelection
      updateQs(this, [
        {
          key: this.customQ,
          value: this.altSelection,
        },
      ])
      this.$emit('click', this.altSelection)
    },
  },
})
