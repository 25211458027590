
import Vue, { PropType } from 'vue'
import SiteTitledSection from '~/components/site/TitledSection.vue'
import { Broadcaster } from '~/models/broadcaster'
import PhoneNumber from '~/components/_general/PhoneNumber.vue'

export default Vue.extend({
  name: 'BroadcasterListenLine',
  components: { PhoneNumber, SiteTitledSection },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      required: true,
    },
  },
  computed: {
    custom(): string | undefined {
      return this.broadcaster.listenLineNumber || undefined
    },
    canListenLine(): boolean {
      if (this.custom) return true
      return this.broadcaster.canWebcast && this.broadcaster.usa
    },
  },
})
