
import Vue, { PropType } from 'vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import { Broadcaster } from '~/models/broadcaster'
import PhoneNumber from '~/components/_general/PhoneNumber.vue'

export default Vue.extend({
  name: 'BroadcasterPhone',
  components: { PhoneNumber, InlineIcon },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
  },
  computed: {
    phone(): string {
      return this.broadcaster?.phone || ''
    },
  },
})
