
import Vue, { PropType } from 'vue'
import { LatLngLiteral } from 'leaflet'
import { Webcast } from '~/models/webcast'
import {
  siteBroadcasterUrl,
  siteSpeakerUrl,
  siteWebcastUrl,
} from '~/assets/ts/utils/urls'
import { DateFormat, WebcastDisplay } from '~/assets/ts/enums'
import { localizeDateTime } from '~/assets/ts/utils/date'
import { openPopoutWindow } from '~/assets/ts/utils/misc'
import { Broadcaster } from '~/models/broadcaster'
import { Speaker } from '~/models/speaker'
import CoreElementWrapper from '~/components/_general/CoreElementWrapper.vue'
import WebcastThumbnail from '~/components/_general/WebcastThumbnail.vue'
import BroadcasterImage from '~/components/_general/BroadcasterImage.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import VerticalAlign from '~/components/_general/VerticalAlign.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import WebcastMetadata from '~/components/_general/WebcastMetadata.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import FollowButton from '~/components/_general/FollowButton.vue'
import ShareButton from '~/components/_general/ShareButton.vue'
import SaLink from '~/components/_general/SaLink.vue'
import LiveIndicator from '~/components/_general/LiveIndicator.vue'

export default Vue.extend({
  name: 'WebcastElement',
  components: {
    LiveIndicator,
    ShareButton,
    FollowButton,
    MarkdownElement,
    WebcastMetadata,
    InlineIcon,
    VerticalAlign,
    LoadingElement,
    BroadcasterImage,
    WebcastThumbnail,
    CoreElementWrapper,
  },
  props: {
    webcast: {
      type: Object as PropType<Webcast>,
      default: undefined,
    },
    display: {
      type: Number as PropType<WebcastDisplay>,
      default: WebcastDisplay.LiveExtended,
    },
    small: {
      type: Boolean,
    },
    loadingLive: {
      type: Boolean,
    },
    hoverPreview: {
      type: Boolean,
    },
    distanceLatLng: {
      type: Object as PropType<LatLngLiteral>,
      default: undefined,
    },
    listBorder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    SaLink() {
      return SaLink
    },
    showThumbnail(): boolean {
      if (this.standard) return this.live
      return this.video || this.featured || this.extended
    },
    displayName(): string {
      return this.broadcaster?.displayName ?? ''
    },
    broadcaster(): Broadcaster | undefined {
      return this.webcast?.broadcaster
    },
    titleUrl(): string {
      if (this.live) return this.liveUrl
      return this.broadcasterUrl
    },
    location(): string {
      return this.webcast?.broadcasterLocation ?? ''
    },
    standard(): boolean {
      return this.isDisplay([WebcastDisplay.Standard])
    },
    video(): boolean {
      return this.isDisplay([WebcastDisplay.LiveVideo])
    },
    extended(): boolean {
      return this.isDisplay([WebcastDisplay.LiveExtended])
    },
    featured(): boolean {
      return this.isDisplay([WebcastDisplay.Featured])
    },
    history(): boolean {
      return this.isDisplay([
        WebcastDisplay.HistoryStandard,
        WebcastDisplay.HistoryStandardWrap,
      ])
    },
    wrap(): boolean {
      return this.isDisplay([
        WebcastDisplay.HistoryStandardWrap,
        WebcastDisplay.LiveExtended,
        WebcastDisplay.Featured,
      ])
    },
    title(): string {
      return this.webcast?.Title(this)
    },
    speaker(): Speaker | undefined {
      return this.webcast?.speaker
    },
    speakerName(): string {
      return this.webcast?.SpeakerName ?? ''
    },
    viewersCount(): number {
      return this.webcast?.totalTuneInCount ?? 0
    },
    live(): boolean {
      if (!this.webcast) return this.loadingLive
      return this.webcast.isLive
    },
    liveUrl(): string {
      return siteWebcastUrl(this.webcast?.broadcasterID)
    },
    duration(): string {
      return this.webcast?.DurationString ?? ''
    },
    date(): string {
      if (!this.webcast) return ''
      return localizeDateTime(this.webcast.StartDate, DateFormat.Date)
    },
    descLineClamp(): string {
      if (this.video) {
        return 'line-clamp-1'
      } else if (this.extended || this.standard) {
        return 'line-clamp-2'
      }
      return 'line-clamp-6'
    },
    description(): string {
      return this.webcast?.description ?? ''
    },
    startTime(): string {
      if (!this.webcast) return ''
      return localizeDateTime(this.webcast.StartDate, DateFormat.Time)
    },
    endTime(): string {
      const endDate = this.webcast?.EndDate
      if (!endDate) return ''
      return localizeDateTime(endDate, DateFormat.Time)
    },
    imageUrl(): string {
      return this.webcast?.ResizableImage(240) ?? ''
    },
    broadcasterUrl(): string {
      return siteBroadcasterUrl(this.broadcaster)
    },
    speakerUrl(): string {
      return siteSpeakerUrl(this.speaker)
    },
    distance(): string {
      if (!this.distanceLatLng || !this.webcast) return ''
      return this.webcast.distanceDisplay(this.distanceLatLng)
    },
  },
  methods: {
    followChanged(followed: boolean) {
      this.$emit('followed', followed)
    },
    isDisplay(list: WebcastDisplay[]) {
      return list.includes(this.display)
    },
    openWebcast(event: Event) {
      event.preventDefault()
      if (!this.webcast) return
      openPopoutWindow(this.liveUrl)
    },
  },
})
