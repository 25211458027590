
import Vue from 'vue'

export default Vue.extend({
  name: 'PhoneNumber',
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
})
